import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Lottie from "lottie-react"
import { motion } from "framer-motion"

const HeaderCategory = ({
  titre,
  texte,
  color,
  json,
  svg,
  illustration,
  ...props
}) => {
  const titreArray = titre.split(" ")

  const [dataJSON, setDataJSON] = useState(null)

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 1,
      },
    },
  }

  const titleVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
  }

  const textVariants = {
    hidden: {
      x: 0,
    },
    visible: {
      x: "100%",
      transition: {
        duration: 1,
      },
    },
  }

  const imageVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
      rotate: 180,
    },
    visible: {
      opacity: 1,
      scale: [0.3, 0.3, 1],
      rotate: 0,
    },
  }

  const bgVariants = {
    hidden: {
      width: "0%",
    },
    visible: {
      width: "100%",
      transition: {
        when: "beforeChildren",
        //staggerChildren: 2.4,
      },
    },
  }

  useEffect(() => {
    fetch(json)
      .then(response => response.json())
      .then(responseJson => {
        setDataJSON(responseJson)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  return (
    <div className="page-categorie">
      <motion.div
        className="entete-categorie max-w-[1920px] mx-auto"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="entete bg-visuall-bg -ml-5 mb-14 pl-5 py-10">
          <motion.div className="grid md:grid-cols-12" variants={titleVariants}>
            <div className="p-4 md:col-span-4">
              <div className="w-24 mb-5">
                <Lottie loop={false} animationData={dataJSON} />
              </div>
              <h1
                className={
                  "relative text-6xl font-bold font-archia pb-5 mb-5 overflow-hidden"
                }
                style={{ color: color }}
              >
                {titreArray[0]} <br />
                <span className={"font-normal"}>{titreArray[1]}</span>
                <motion.div
                  className="absolute z-10 inset-0 bg-visuall-bg"
                  variants={textVariants}
                />
              </h1>
              <p className="relative text-2xl font-display text-visuall-gris-logo overflow-hidden">
                <div>{texte}</div>
                <motion.div
                  className="absolute z-10 inset-0 bg-visuall-bg"
                  variants={textVariants}
                />
              </p>
            </div>
            <div
              className="relative -mb-20 overflow-hidden md:col-span-8 lg:col-start-6"
              variants={imageVariants}
            >
              <GatsbyImage
                image={illustration}
                alt={`illustration ${titre}`}
                className={""}
              />
              <motion.div
                className="absolute z-10 inset-0 bg-visuall-bg"
                variants={textVariants}
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  )
}

HeaderCategory.propTypes = {}

export default HeaderCategory
